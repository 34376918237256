import baseService from '../baseService';
const request = baseService('icon');

export default {
  getIconList(params) {
    return request('/getIconList', params, 'get');
  },
  deleteIcons(params) {
    return request('/deleteIcons', params, 'delete');
  },
  getIconDetail(params) {
    return request('/getIconDetail', params, 'get');
  },
  addIcon(params) {
    return request('/addIcon', params, 'post');
  },
  editIcon(params) {
    return request('/editIcon', params, 'put');
  },
  getIcons(params) {
    return request('/getIcons', params, 'get');
  }
};
