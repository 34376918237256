<template>
  <div class="flex_column iconManage">
    <dog-search
      ref="search"
      :config="searchConfig"
      @search="search"
    ></dog-search>
    <dog-table
      ref="dogTable"
      id="d1901a89-1156-4336-8d8b-6653e1e875ab"
      :columns="tableColumns"
      :service="getList"
      column-type="selection"
      @selection-change="selectedData = $event"
    >
      <permission-button slot="btn" :config="btnConfig"> </permission-button>
    </dog-table>
    <add-icon-dialog
      ref="addIconDialog"
      @confirm="searchData"
    ></add-icon-dialog>
  </div>
</template>

<script>
  import iconService from '@/api/service/icon';
  import addIconDialog from './addIconDialog.vue';

  export default {
    name: 'iconManage',
    components: { addIconDialog },
    data() {
      return {
        searchConfig: [
          {
            type: 'input',
            label: '图标名',
            key: 'name'
          },
          {
            type: 'input',
            label: '描述',
            key: 'desc'
          }
        ],
        tableColumns: [
          {
            prop: 'operate',
            label: '操作',
            width: 60,
            widthGrow: false,
            render: (h, { props: { row } }) => (
              <permission-element
                config={[
                  {
                    code: 'editIcon',
                    callback: () => this.$refs.addIconDialog.openFrame(row.id),
                    disabled: row.modifiable === '0'
                  }
                ]}></permission-element>
            )
          },
          { prop: 'name', label: '图标名', width: 100 },
          {
            prop: 'icon',
            label: '图标',
            width: 60,
            widthGrow: false,
            render: (h, { props: { row } }) => (
              <i class={'iconfont ' + row.name}></i>
            )
          },
          { prop: 'desc', label: '描述', width: 120 }
        ],
        btnConfig: [
          {
            buttonType: 'primary',
            code: 'addIcon',
            callback: () => this.$refs.addIconDialog.openFrame()
          },
          {
            buttonType: 'danger',
            code: 'deleteIcons',
            callback: this.deleteIcons
          }
        ],
        selectedData: []
      };
    },
    mounted() {
      this.searchData();
    },
    methods: {
      searchData() {
        this.$refs.search.search();
      },
      search(query) {
        this.$refs.dogTable.search(query);
      },
      getList(params) {
        return iconService.getIconList(params);
      },
      deleteIcons() {
        if (this.selectedData.length === 0) {
          return this.$message.error('请至少选择一条数据！');
        }
        this.$confirm(`是否确定删除所选数据？`, '提示', {
          type: 'warning'
        }).then(() => {
          iconService
            .deleteIcons({ ids: this.selectedData.map((item) => item.id) })
            .then(this.searchData);
        });
      }
    }
  };
</script>

<style lang="scss" scoped></style>
